<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('new_register_update')" :isFilter="false"></Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('new_register_update')" :isFilter="false"></HeaderMobile>
            </template>
            <div class="row data-form register_form">
                <div class="col-12 col-md-12">
                    <ValidationObserver ref="registerForm">
                        <div class="border px-5 pt-5 pb-2 mb-1 rounded">
                            <div class="row">
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <ValidationProvider name="semester_id" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('semester')">
                                            <semesters-selectbox
                                                :validate-error="errors[0]"
                                                :setActive="true"
                                                v-model="registerForm.semester_id">
                                            </semesters-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <ValidationProvider name="type" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('registration_type')">
                                            <parameter-selectbox
                                                code="registration_types"
                                                :validate-error="errors[0]"
                                                v-model="registerForm.type">
                                            </parameter-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <!--                <div class="col-12 col-sm-4 col-md-4 col-lg-4">-->
                                <!--                  <ValidationProvider name="scholarship_rate" rules="required|min_value:0|max_value:100"-->
                                <!--                                      v-slot="{valid, errors}">-->
                                <!--                    <b-form-group :label="$t('scholarship_rate')">-->
                                <!--                      <b-form-input type="number" v-model="registerForm.scholarship_rate"-->
                                <!--                                    :state="errors[0] ? false : null"/>-->
                                <!--                      <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>-->
                                <!--                      &lt;!&ndash; <scholarship-rate-selectbox-->
                                <!--                          :validateError="errors[0]"-->
                                <!--                          v-model="registerForm.scholarship_rate">-->
                                <!--                      </scholarship-rate-selectbox> &ndash;&gt;-->
                                <!--                    </b-form-group>-->
                                <!--                  </ValidationProvider>-->
                                <!--                </div>-->
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <ValidationProvider name="faculty" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('faculty')">
                                            <faculty-selectbox
                                                :validateError="errors[0]"
                                                v-model="registerForm.faculty_code">
                                            </faculty-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <ValidationProvider name="department" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('department')">
                                            <department-selectbox
                                                :faculty_code="registerForm.faculty_code"
                                                :validateError="errors[0]"
                                                v-model="registerForm.department_code">
                                            </department-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <ValidationProvider name="program_code" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('program')">
                                            <program-selectbox
                                                :faculty_code="registerForm.faculty_code"
                                                :department_code="registerForm.department_code"
                                                :is-active="true"
                                                :validateError="errors[0]"
                                                v-model="registerForm.program_code">
                                            </program-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <b-tabs content-class="py-5 position-relative" class="mt-5" v-model="tabIndex">
                            <div class="tabs-bottom-line"></div>
                            <b-tab active ref="tab1">
                                <template #title><i class="ri-profile-line top-plus-2 mr-2"></i>{{ $t('identity') }}
                                </template>
                                <div class="row">
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="passport_number"
                                                            rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('passport_number')">
                                                <b-form-input
                                                    v-model="registerForm.passport_number"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="name"
                                                            rules="required"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('name')">
                                                <b-form-input
                                                    v-model="registerForm.name"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="surname"
                                                            rules="required"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('surname')">
                                                <b-form-input
                                                    v-model="registerForm.surname"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <b-form-group :label="$t('middle_name')">
                                            <b-form-input v-model="registerForm.middle_name"
                                            >
                                            </b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="birthdate"
                                                            rules="required"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('date_of_birth')">
                                                <select-date v-model="registerForm.birthdate"
                                                             :validation-error="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <!--                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">-->
                                    <!--                                        <ValidationProvider name="birthplace"-->
                                    <!--                                                            rules="required"-->
                                    <!--                                                            v-slot="{valid, errors}">-->
                                    <!--                                            <b-form-group :label="$t('birthplace')">-->
                                    <!--                                                <b-form-input-->
                                    <!--                                                    v-mask="upperAlphaMask"-->
                                    <!--                                                    v-model="registerForm.birthplace"-->
                                    <!--                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>-->
                                    <!--                                                <b-form-invalid-feedback v-if="errors[0]"-->
                                    <!--                                                                         v-html="errors[0]"></b-form-invalid-feedback>-->
                                    <!--                                            </b-form-group>-->
                                    <!--                                        </ValidationProvider>-->
                                    <!--                                    </div>-->
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="gender"
                                                            rules="required"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('gender')">
                                                <gender-selectbox
                                                    :validate-error="errors[0]"
                                                    v-model="registerForm.gender">
                                                </gender-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="nationality_code"
                                                            rules="required"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('nationality')">
                                                <country-selectbox
                                                    valueType="code"
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.nationality_code">
                                                </country-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="mother_name"

                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('mother_name')">
                                                <b-form-input
                                                    v-model="registerForm.mother_name"
                                                    :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <b-form-group :label="$t('father_name')">
                                            <b-form-input
                                                v-model="registerForm.father_name"></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="city_id" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('city_name')">
                                                <city-selectbox
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.city_id">
                                                </city-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="legal_presence_type" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('legal_presence_type')">
                                                <parameter-selectbox
                                                    code="legal_presence_types"
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.legal_presence_type ">
                                                </parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <!--                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">-->
                                    <!--                                        <ValidationProvider name="district_id" rules="" v-slot="{valid, errors}">-->
                                    <!--                                            <b-form-group :label="$t('district_name')">-->
                                    <!--                                                <district-outline-selectbox-->
                                    <!--                                                    :city_id="registerForm.city_id"-->
                                    <!--                                                    :validateError="errors[0]"-->
                                    <!--                                                    v-model="registerForm.district_id">-->
                                    <!--                                                </district-outline-selectbox>-->
                                    <!--                                            </b-form-group>-->
                                    <!--                                        </ValidationProvider>-->
                                    <!--                                    </div>-->
                                </div>
                            </b-tab>
                            <b-tab ref="tab2">
                                <template #title><i class="ri-map-pin-line top-plus-2 mr-2"></i>{{ $t('address') }}
                                </template>
                                <div class="row">
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="address_city_id" rules="required"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('state')">
                                                <city-selectbox
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.address_city_id">
                                                </city-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="address_district_id" rules="required"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('city')">
                                                <district-outline-selectbox
                                                    :city_id="registerForm.address_city_id"
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.address_district_id">
                                                </district-outline-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="address" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('address')">
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.address"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="address_zip_code" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('zip_code')">
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.address_zip_code"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="apartment" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('apartment')">
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.apartment"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </b-tab>
                            <!--                            <b-tab ref="tab3">-->
                            <!--                                <template #title><i class="ri-team-line top-plus-2 mr-2"></i>{{ $t('family_info') }}-->
                            <!--                                </template>-->
                            <!--                                <div class="row">-->
                            <!--                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">-->
                            <!--                                        <ValidationProvider name="mothers_job" rules="" v-slot="{valid, errors}">-->
                            <!--                                            <b-form-group :label="$t('mother_job')">-->
                            <!--                                                <parameter-selectbox-->
                            <!--                                                    @input="handleJobInput($event, 'mother')"-->
                            <!--                                                    code="jobs"-->
                            <!--                                                    :validateError="errors[0]"-->
                            <!--                                                    v-model="registerForm.mothers_job">-->
                            <!--                                                </parameter-selectbox>-->
                            <!--                                            </b-form-group>-->
                            <!--                                        </ValidationProvider>-->
                            <!--                                    </div>-->
                            <!--                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">-->
                            <!--                                        <ValidationProvider name="mothers_education" rules="" v-slot="{valid, errors}">-->
                            <!--                                            <b-form-group :label="$t('mothers_education')">-->
                            <!--                                                <parameter-selectbox-->
                            <!--                                                    code="education_statuses"-->
                            <!--                                                    sort="rank"-->
                            <!--                                                    :validateError="errors[0]"-->
                            <!--                                                    v-model="registerForm.mothers_education">-->
                            <!--                                                </parameter-selectbox>-->
                            <!--                                            </b-form-group>-->
                            <!--                                        </ValidationProvider>-->
                            <!--                                    </div>-->
                            <!--                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">-->
                            <!--                                        <ValidationProvider name="mothers_phone" rules="" v-slot="{valid, errors}">-->
                            <!--                                            <b-form-group :label="$t('mother_gsm')">-->
                            <!--                                                <b-form-input-->
                            <!--                                                    :state="errors[0] ? false : (valid ? true : null)"-->
                            <!--                                                    v-model="registerForm.mothers_phone"></b-form-input>-->
                            <!--                                                <b-form-invalid-feedback v-if="errors[0]"-->
                            <!--                                                                         v-html="errors[0]"></b-form-invalid-feedback>-->
                            <!--                                            </b-form-group>-->
                            <!--                                        </ValidationProvider>-->
                            <!--                                    </div>-->
                            <!--                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4"-->
                            <!--                                         v-if="registerForm.mothers_job == 'Diğer'">-->
                            <!--                                        <ValidationProvider name="mothers_job_other"-->
                            <!--                                                            rules="required_if:mothersJob,Diğer"-->
                            <!--                                                            v-slot="{valid, errors}">-->
                            <!--                                            <b-form-group :label="$t('job_other')">-->
                            <!--                                                <b-form-input-->
                            <!--                                                    :state="errors[0] ? false : (valid ? true : null)"-->
                            <!--                                                    v-model="registerForm.mothers_job_other"></b-form-input>-->
                            <!--                                                <b-form-invalid-feedback v-if="errors[0]"-->
                            <!--                                                                         v-html="errors[0]"></b-form-invalid-feedback>-->
                            <!--                                            </b-form-group>-->
                            <!--                                        </ValidationProvider>-->
                            <!--                                    </div>-->
                            <!--                                </div>-->
                            <!--                                <div class="row">-->
                            <!--                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">-->
                            <!--                                        <ValidationProvider name="fathers_job" rules="" v-slot="{valid, errors}">-->
                            <!--                                            <b-form-group :label="$t('father_job')">-->
                            <!--                                                <parameter-selectbox-->
                            <!--                                                    @input="handleJobInput($event, 'father')"-->
                            <!--                                                    code="jobs"-->
                            <!--                                                    :validateError="errors[0]"-->
                            <!--                                                    v-model="registerForm.fathers_job">-->
                            <!--                                                </parameter-selectbox>-->
                            <!--                                            </b-form-group>-->
                            <!--                                        </ValidationProvider>-->
                            <!--                                    </div>-->
                            <!--                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">-->
                            <!--                                        <ValidationProvider name="fathers_education" rules="" v-slot="{valid, errors}">-->
                            <!--                                            <b-form-group :label="$t('fathers_education')">-->
                            <!--                                                <parameter-selectbox-->
                            <!--                                                    code="education_statuses"-->
                            <!--                                                    :validateError="errors[0]"-->
                            <!--                                                    sort="rank"-->
                            <!--                                                    v-model="registerForm.fathers_education">-->
                            <!--                                                </parameter-selectbox>-->
                            <!--                                            </b-form-group>-->
                            <!--                                        </ValidationProvider>-->
                            <!--                                    </div>-->
                            <!--                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">-->
                            <!--                                        <ValidationProvider name="fathers_phone" rules="" v-slot="{valid, errors}">-->
                            <!--                                            <b-form-group :label="$t('father_gsm')">-->
                            <!--                                                <b-form-input-->
                            <!--                                                    :state="errors[0] ? false : (valid ? true : null)"-->
                            <!--                                                    v-model="registerForm.fathers_phone"></b-form-input>-->
                            <!--                                                <b-form-invalid-feedback v-if="errors[0]"-->
                            <!--                                                                         v-html="errors[0]"></b-form-invalid-feedback>-->
                            <!--                                            </b-form-group>-->
                            <!--                                        </ValidationProvider>-->
                            <!--                                    </div>-->
                            <!--                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4"-->
                            <!--                                         v-if="registerForm.fathers_job == 'Diğer'">-->
                            <!--                                        <ValidationProvider name="fathers_job_other"-->
                            <!--                                                            rules="required_if:fathersJob,Diğer"-->
                            <!--                                                            v-slot="{valid, errors}">-->
                            <!--                                            <b-form-group :label="$t('job_other')">-->
                            <!--                                                <b-form-input-->
                            <!--                                                    :state="errors[0] ? false : (valid ? true : null)"-->
                            <!--                                                    v-model="registerForm.fathers_job_other"></b-form-input>-->
                            <!--                                                <b-form-invalid-feedback v-if="errors[0]"-->
                            <!--                                                                         v-html="errors[0]"></b-form-invalid-feedback>-->
                            <!--                                            </b-form-group>-->
                            <!--                                        </ValidationProvider>-->
                            <!--                                    </div>-->
                            <!--                                </div>-->
                            <!--                            </b-tab>-->
                            <b-tab ref="tab4">
                                <template #title><i class="ri-phone-line top-plus-2 mr-2"></i>{{ $t('contact') }}
                                </template>
                                <div class="row">
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="mobile_tel" :rules="role=='A' || role=='OL'?'':'required'" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('gsm_no')">
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-mask="'+1 (###)-###-####'"
                                                    v-model="registerForm.mobile_tel"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="home_tel" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('home_tel')">
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.home_tel"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="email" rules="required|email" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('email')">
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.email"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 mb-2 mt-4">
                                        <h6 class="text-uppercase mb-0">{{ $t('emergency_contact_txt').toUpper() }}</h6>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="emergency_person_relation" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('degree_of_proximity')">
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.emergency_person_relation"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="emergency_person_name" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('name_surname')">
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.emergency_person_name"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="emergency_person_phone" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('mobile_number')">
                                                <b-form-input
                                                    v-mask="'(5##)-###-####'"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.emergency_person_phone"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </b-tab>
                            <!--              <b-tab ref="tab5">-->
                            <!--                <template #title><i class="ri-pulse-line top-plus-2 mr-2"></i>{{ $t('health') }}-->
                            <!--                </template>-->
                            <!--                <div class="row">-->
                            <!--                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">-->
                            <!--                    <ValidationProvider name="surgery_illness_history" rules=""-->
                            <!--                                        v-slot="{valid, errors}">-->
                            <!--                      <b-form-group :label="$t('surgery_illness_history')">-->
                            <!--                        <b-form-input-->
                            <!--                            :state="errors[0] ? false : (valid ? true : null)"-->
                            <!--                            v-model="registerForm.surgery_illness_history"></b-form-input>-->
                            <!--                        <b-form-invalid-feedback v-if="errors[0]"-->
                            <!--                                                 v-html="errors[0]"></b-form-invalid-feedback>-->
                            <!--                      </b-form-group>-->
                            <!--                    </ValidationProvider>-->
                            <!--                  </div>-->
                            <!--                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">-->
                            <!--                    <ValidationProvider name="medicines" rules="" v-slot="{valid, errors}">-->
                            <!--                      <b-form-group :label="$t('medicines')">-->
                            <!--                        <b-form-input-->
                            <!--                            :state="errors[0] ? false : (valid ? true : null)"-->
                            <!--                            v-model="registerForm.medicines"></b-form-input>-->
                            <!--                        <b-form-invalid-feedback v-if="errors[0]"-->
                            <!--                                                 v-html="errors[0]"></b-form-invalid-feedback>-->
                            <!--                      </b-form-group>-->
                            <!--                    </ValidationProvider>-->
                            <!--                  </div>-->
                            <!--                  <div class="col-12 col-sm-4 col-md-4 col-lg-4">-->
                            <!--                    <ValidationProvider name="type_of_disability" rules="" v-slot="{valid, errors}">-->
                            <!--                      <b-form-group :label="$t('disability_type')">-->
                            <!--                        <parameter-selectbox-->
                            <!--                            code="disabled_types"-->
                            <!--                            :validateError="errors[0]"-->
                            <!--                            v-model="registerForm.type_of_disability">-->
                            <!--                        </parameter-selectbox>-->
                            <!--                      </b-form-group>-->
                            <!--                    </ValidationProvider>-->
                            <!--                  </div>-->
                            <!--                  <div class="col-12 col-sm-4 col-md-4 col-lg-4">-->
                            <!--                    <ValidationProvider name="disability_rate" rules="" v-slot="{valid, errors}">-->
                            <!--                      <b-form-group :label="$t('disability_rate')">-->
                            <!--                        <b-form-select-->
                            <!--                            v-model="registerForm.disability_rate">-->
                            <!--                          <option value="">{{ $t('select') }}</option>-->
                            <!--                          <option v-for="(row, rowindex) in 20" :key="rowindex"-->
                            <!--                                  :value="row*5">{{ row * 5 }}-->
                            <!--                          </option>-->
                            <!--                        </b-form-select>-->
                            <!--                        <b-form-invalid-feedback v-if="errors[0]"-->
                            <!--                                                 v-html="errors[0]"></b-form-invalid-feedback>-->
                            <!--                      </b-form-group>-->
                            <!--                    </ValidationProvider>-->
                            <!--                  </div>-->
                            <!--                </div>-->
                            <!--              </b-tab>-->
                            <b-tab ref="tab6">
                                <template #title><i class="ri-building-line top-plus-2 mr-2"></i>{{
                                        $t('education_info')
                                    }}
                                </template>
                                <div class="row">
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="last_education_status" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('last_education_status')">
                                                <parameter-selectbox
                                                    code="education_statuses"
                                                    :validateError="errors[0]"
                                                    sort="rank"
                                                    v-model="registerForm.last_education_status">
                                                </parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div  class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="university_status" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('university_status')">
                                                <multiselect v-model="registerForm.university_status"
                                                             :options="university_type"
                                                             :multiple="false"
                                                             :select-label="''"
                                                             :selected-label="''"
                                                             :deselect-label="''"
                                                             :placeholder="$t('select')"
                                                             :searchable="true"
                                                />
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"
                                                                         class="mb-2"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4"
                                         v-if="registerForm.last_education_status != 'high_school'">
                                        <ValidationProvider name="last_university" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('last_school')">
                                                <university-with-filter-selectbox
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.last_university_id">
                                                </university-with-filter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4"
                                         v-if="registerForm.last_education_status == 'high_school'">
                                        <ValidationProvider name="last_school" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('last_school')">
                                                <school-selectbox
                                                    valueType="code"
                                                    :validateError="errors[0]"
                                                    v-model="registerForm.last_school">
                                                </school-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="last_school_registration_date" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('last_school_registration_date')">
                                                <select-date v-model="registerForm.last_school_registration_date"
                                                             :validation-error="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="last_school_date_of_graduation" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('last_school_date_of_graduation')">
                                                <select-date v-model="registerForm.last_school_date_of_graduation"
                                                             :validation-error="errors[0]"/>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="last_school_diploma_number" rules=""
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('last_school_diploma_number')">
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.last_school_diploma_number"></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                        <ValidationProvider name="last_school_diploma_point" rules="double"
                                                            v-slot="{valid, errors}">
                                            <b-form-group :label="$t('last_school_diploma_point')">
                                                <b-form-input
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    v-model="registerForm.last_school_diploma_point"
                                                    v-decimal-numbers
                                                    max="100"
                                                    min="0"
                                                ></b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </b-tab>
                            <!--                            <b-tab ref="tab7">-->
                            <!--                                <template #title><i-->
                            <!--                                    class="ri-building-line top-plus-2 mr-2"></i>{{ $t('requests_info') }}-->
                            <!--                                </template>-->
                            <!--                                <div class="row">-->
                            <!--                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">-->
                            <!--                                        <ValidationProvider name="want_leave_of_absence" rules=""-->
                            <!--                                                            v-slot="{valid, errors}">-->
                            <!--                                            <b-form-group :label="$t('want_leave_of_absence')"-->
                            <!--                                                          v-slot="{ ariaDescribedby }">-->
                            <!--                                                <b-form-radio-group v-model="registerForm.want_leave_of_absence"-->
                            <!--                                                                    :aria-describedby="ariaDescribedby">-->
                            <!--                                                    <b-form-radio value="E">{{ $t('yes') }}</b-form-radio>-->
                            <!--                                                    <b-form-radio value="H">{{ $t('no') }}</b-form-radio>-->
                            <!--                                                </b-form-radio-group>-->
                            <!--                                                <b-form-invalid-feedback v-if="errors[0]"-->
                            <!--                                                                         v-html="errors[0]"></b-form-invalid-feedback>-->
                            <!--                                            </b-form-group>-->
                            <!--                                        </ValidationProvider>-->
                            <!--                                    </div>-->
                            <!--                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12"-->
                            <!--                                         v-if="registerForm.want_leave_of_absence=='E'">-->
                            <!--                                        <div class="row">-->
                            <!--                                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">-->
                            <!--                                                <ValidationProvider name="reason_for_leave_of_absence"-->
                            <!--                                                                    :rules="registerForm.want_leave_of_absence == 'E' ? 'required' : ''"-->
                            <!--                                                                    v-slot="{valid, errors}">-->
                            <!--                                                    <b-form-group :label="$t('leave_of_absence_reason')">-->
                            <!--                                                        <parameter-selectbox-->
                            <!--                                                            code="leave_of_absence_reasons"-->
                            <!--                                                            :validateError="errors[0]"-->
                            <!--                                                            v-model="registerForm.reason_for_leave_of_absence">-->
                            <!--                                                        </parameter-selectbox>-->
                            <!--                                                    </b-form-group>-->
                            <!--                                                </ValidationProvider>-->
                            <!--                                            </div>-->
                            <!--                                            <div class="col-12 col-sm-6 col-md-6 col-lg-6">-->
                            <!--                                                <ValidationProvider name="leave_of_absence_semesters"-->
                            <!--                                                                    :rules="registerForm.want_leave_of_absence == 'E' ? 'required' : ''"-->
                            <!--                                                                    v-slot="{valid, errors}">-->
                            <!--                                                    <b-form-group :label="$t('leave_of_absence_semesters')">-->
                            <!--                                                        <b-form-select-->
                            <!--                                                            v-model="registerForm.leave_of_absence_semesters"-->
                            <!--                                                            :options="leaveOfAbsenceSemesterOptions"></b-form-select>-->
                            <!--                                                        <b-form-invalid-feedback v-if="errors[0]"-->
                            <!--                                                                                 v-html="errors[0]"></b-form-invalid-feedback>-->
                            <!--                                                    </b-form-group>-->
                            <!--                                                </ValidationProvider>-->
                            <!--                                            </div>-->
                            <!--                                        </div>-->
                            <!--                                    </div>-->
                            <!--                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12" v-if="programLanguage == 'TR'">-->
                            <!--                                        <ValidationProvider name="want_optional_prep" rules="" v-slot="{valid, errors}">-->
                            <!--                                            <b-form-group v-slot="{ ariaDescribedby }">-->
                            <!--                                                <label>{{ $t('want_optional_prep') }} *</label>-->
                            <!--                                                <b-form-radio-group v-model="registerForm.want_optional_prep"-->
                            <!--                                                                    :aria-describedby="ariaDescribedby">-->
                            <!--                                                    <b-form-radio value="E">{{ $t('yes') }}</b-form-radio>-->
                            <!--                                                    <b-form-radio value="H">{{ $t('no') }}</b-form-radio>-->
                            <!--                                                </b-form-radio-group>-->
                            <!--                                                <b-form-invalid-feedback v-if="errors[0]"-->
                            <!--                                                                         v-html="errors[0]"></b-form-invalid-feedback>-->
                            <!--                                            </b-form-group>-->
                            <!--                                        </ValidationProvider>-->
                            <!--                                    </div>-->
                            <!--                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12" v-if="programLanguage == 'EN'">-->
                            <!--                                        <ValidationProvider name="has_english_proficiency_certificate" rules=""-->
                            <!--                                                            v-slot="{valid, errors}">-->
                            <!--                                            <b-form-group v-slot="{ ariaDescribedby }">-->
                            <!--                                                <label>{{ $t('do_you_have_language_proficiency') }}</label>-->
                            <!--                                                <b-form-radio-group-->
                            <!--                                                    v-model="registerForm.has_english_proficiency_certificate"-->
                            <!--                                                    :aria-describedby="ariaDescribedby">-->
                            <!--                                                    <b-form-radio value="E">{{ $t('yes') }}</b-form-radio>-->
                            <!--                                                    <b-form-radio value="H">{{ $t('no') }}</b-form-radio>-->
                            <!--                                                </b-form-radio-group>-->
                            <!--                                                <b-form-invalid-feedback v-if="errors[0]"-->
                            <!--                                                                         v-html="errors[0]"></b-form-invalid-feedback>-->
                            <!--                                            </b-form-group>-->
                            <!--                                        </ValidationProvider>-->
                            <!--                                    </div>-->
                            <!--                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">-->
                            <!--                                        <div class="row" v-if="registerForm.has_english_proficiency_certificate == 'E'">-->
                            <!--                                            <div class="col-12 col-sm-4 col-md-4 col-lg-4">-->
                            <!--                                                <ValidationProvider name="english_proficiency_certificate_type"-->
                            <!--                                                                    vid="english_proficiency_certificate_type"-->
                            <!--                                                                    :rules="registerForm.has_english_proficiency_certificate == 'E' ? 'required' : ''"-->
                            <!--                                                                    v-slot="{valid, errors}">-->
                            <!--                                                    <b-form-group :label="$t('cert_type')">-->
                            <!--                                                        <parameter-selectbox-->
                            <!--                                                            code="english_proficiency_certificate_types"-->
                            <!--                                                            :validateError="errors[0]"-->
                            <!--                                                            v-model="registerForm.english_proficiency_certificate_type">-->
                            <!--                                                        </parameter-selectbox>-->
                            <!--                                                    </b-form-group>-->
                            <!--                                                </ValidationProvider>-->
                            <!--                                            </div>-->
                            <!--                                            <div class="col-12 col-sm-4 col-md-4 col-lg-4">-->
                            <!--                                                <ValidationProvider name="english_proficiency_exam_date"-->
                            <!--                                                                    :rules="registerForm.has_english_proficiency_certificate == 'E' ? 'required' : ''"-->
                            <!--                                                                    v-slot="{valid, errors}">-->
                            <!--                                                    <b-form-group :label="$t('exam_date')">-->
                            <!--                                                        <b-form-input-->
                            <!--                                                            type="date"-->
                            <!--                                                            v-model="registerForm.english_proficiency_exam_date">-->
                            <!--                                                        </b-form-input>-->
                            <!--                                                        <b-form-invalid-feedback v-if="errors[0]"-->
                            <!--                                                                                 v-html="errors[0]"></b-form-invalid-feedback>-->
                            <!--                                                    </b-form-group>-->
                            <!--                                                </ValidationProvider>-->
                            <!--                                            </div>-->
                            <!--                                            <div class="col-12 col-sm-4 col-md-4 col-lg-4">-->
                            <!--                                                <ValidationProvider name="english_proficiency_exam_score"-->
                            <!--                                                                    :rules="registerForm.has_english_proficiency_certificate == 'E' ? 'required' : ''"-->
                            <!--                                                                    v-slot="{valid, errors}">-->
                            <!--                                                    <b-form-group :label="$t('exam_score')">-->
                            <!--                                                        <b-form-input-->
                            <!--                                                            v-model="registerForm.english_proficiency_exam_score">-->
                            <!--                                                        </b-form-input>-->
                            <!--                                                        <b-form-invalid-feedback v-if="errors[0]"-->
                            <!--                                                                                 v-html="errors[0]"></b-form-invalid-feedback>-->
                            <!--                                                    </b-form-group>-->
                            <!--                                                </ValidationProvider>-->
                            <!--                                            </div>-->
                            <!--                                            <div class="col-12 col-sm-4 col-md-4 col-lg-4"-->
                            <!--                                                 v-if="this.registerForm.english_proficiency_certificate_type == 'toefl'">-->
                            <!--                                                <ValidationProvider name="toefl_username"-->
                            <!--                                                                    rules="required_if:english_proficiency_certificate_type,toefl"-->
                            <!--                                                                    v-slot="{valid, errors}">-->
                            <!--                                                    <b-form-group>-->
                            <!--                                                        <label>{{ $t('toefl_username') }}</label>-->
                            <!--                                                        <b-form-input-->
                            <!--                                                            :state="errors[0] ? false : (valid ? true : null)"-->
                            <!--                                                            v-model="registerForm.toefl_username"></b-form-input>-->
                            <!--                                                        <b-form-invalid-feedback v-if="errors[0]"-->
                            <!--                                                                                 v-html="errors[0]"></b-form-invalid-feedback>-->
                            <!--                                                    </b-form-group>-->
                            <!--                                                </ValidationProvider>-->
                            <!--                                            </div>-->
                            <!--                                            <div class="col-12 col-sm-4 col-md-4 col-lg-4"-->
                            <!--                                                 v-if="this.registerForm.english_proficiency_certificate_type == 'toefl'">-->
                            <!--                                                <ValidationProvider name="toefl_password"-->
                            <!--                                                                    rules="required_if:english_proficiency_certificate_type,toefl"-->
                            <!--                                                                    v-slot="{valid, errors}">-->
                            <!--                                                    <b-form-group>-->
                            <!--                                                        <label>{{ $t('toefl_password') }} *</label>-->
                            <!--                                                        <b-form-input-->
                            <!--                                                            :state="errors[0] ? false : (valid ? true : null)"-->
                            <!--                                                            v-model="registerForm.toefl_password"></b-form-input>-->
                            <!--                                                        <b-form-invalid-feedback v-if="errors[0]"-->
                            <!--                                                                                 v-html="errors[0]"></b-form-invalid-feedback>-->
                            <!--                                                    </b-form-group>-->
                            <!--                                                </ValidationProvider>-->
                            <!--                                            </div>-->

                            <!--                                        </div>-->
                            <!--                                    </div>-->
                            <!--                                </div>-->
                            <!--                            </b-tab>-->
                        </b-tabs>
                    </ValidationObserver>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                    <b-form-group>
                        <b-button variant="primary" @click="sendForm">{{ $t('save') }}</b-button>
                    </b-form-group>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
    import Header from "@/layouts/AppLayout/Header";
    import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

    import AppLayout from "@/layouts/AppLayout"
    import {ValidationObserver, ValidationProvider} from "vee-validate";
    import GenderSelectbox from "@/components/interactive-fields/GenderSelectbox";
    import CountrySelectbox from "@/components/interactive-fields/CountrySelectbox";
    import CitySelectbox from "@/components/interactive-fields/CitySelectbox";
    import DistrictOutlineSelectbox from "@/components/interactive-fields/DistrictOutlineSelectbox";
    import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
    import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
    import ScholarshipRateSelectbox from "@/components/interactive-fields/ScholarshipRateSelectbox";
    import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
    import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
    import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
    import ProgramService from "@/services/ProgramService";
    import RegistrationService from "@/services/RegistrationService";
    import moment from "moment";
    import SchoolSelectbox from "@/components/interactive-fields/SchoolSelectbox";
    import UniversityWithFilterSelectbox from "@/components/interactive-fields/UniversityWithFilterSelectbox";
    import StudentDocumentService from "@/services/StudentDocumentService";
    import ParameterService from "@/services/ParameterService";
    import SelectDate from "@/components/interactive-fields/SelectDate";

    export default {
        components: {
            SelectDate,
            UniversityWithFilterSelectbox,
            AppLayout,
            ValidationObserver,
            ValidationProvider,
            GenderSelectbox,
            CountrySelectbox,
            CitySelectbox,
            DistrictOutlineSelectbox,
            ParameterSelectbox,
            SemestersSelectbox,
            ScholarshipRateSelectbox,
            FacultySelectbox,
            DepartmentSelectbox,
            ProgramSelectbox,
            Header,
            HeaderMobile,
            SchoolSelectbox
        },
        metaInfo() {
            return {
                title: this.$t('update_register')
            }
        },
        data() {
            return {
                associate_degree:"associate_degree",
                doctorate:"doctorate",
                graduate:"graduate",
                undergraduate:"undergraduate",
                high_school:"high_school",
                university_status:null,
                tabIndex: 1,
                programLanguage: null,
                university_type:["Yurt İçi","Yurt Dışı"],
                registerForm: {

                },

                leaveOfAbsenceSemesterOptions: [
                    {value: null, text: this.$t('select')},
                    {value: 1, text: this.$t('fall')},
                    {value: 2, text: this.$t('spring')},
                    {value: 3, text: this.$t('fall_and_spring')}
                ],

                documents: null,
                data:null,
                files: [],
                dates: [],

                upperAlphaMask: {
                    mask: 'UUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUUU',
                    tokens: {
                        'U': {
                            pattern: /[A-Za-z ÇĞİÖŞÜçğıöşü]/,
                            transform: function(v) {
                                v=v.replaceAll('i','İ');
                                return v.toUpper();
                            }
                        }
                    }
                },

                parameterJobs: null
            }
        },
        computed:{
            role(){
                const roles=this.$store.getters["auth/getUser"];
                return roles && roles.active_role ? roles.active_role.name : null;
            },
        },

        methods: {
            nameWithLang ({ name}) {
                return `${name}`
            },


            getData(){
                if(this.$route.params.id){
                    RegistrationService.show(this.$route.params.id).then(response => {
                        let data = response.data.data
                        this.data=data
                        this.registerForm=data


                    })
                }
                else {
                    this.$router.push('/404')
                }
            },

            getDocuments(){
                RegistrationService.getDocuments(this.$route.params.id)
                                   .then(response => {
                                       this.documents = response.data.data
                                   })
            },

            async sendForm(){
                const isValid = await this.$refs.registerForm.validate();
                if (isValid) {

                    this.$swal({
                        title: this.$t('attention'),
                        text: this.$t('prep_classes_update_confirm_text'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('no')
                    }).then(response => {
                        if(response.isConfirmed == true){
                            RegistrationService.update(this.$route.params.id, this.registerForm).then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message));
                            }).catch(e => {
                                this.showErrors(e)
                                // if (e.status == '406') { ///406 geldiği zaman değiştirilecek
                                //     if (e.data.message) {
                                //         this.$toast.error(this.$t('api.' + e.data.message));
                                //     }
                                // }
                            })

                        }
                    })
                } else {

                    {
                        if(this.registerForm.name == null || this.registerForm.name == '' ||
                            this.registerForm.surname == null || this.registerForm.surname == '' || this.registerForm.birthplace == null || this.registerForm.birthplace == ''
                            || this.registerForm.birthdate == null || this.registerForm.birthdate == '' || this.registerForm.nationality == null || this.registerForm.nationality == '' ||
                            this.registerForm.motherName == null || this.registerForm.motherName == '') {
                            this.tabIndex = 0
                        } else if(this.registerForm.address == null || this.registerForm.address == ''){
                            this.tabIndex = 1
                        } else if(this.registerForm.mobileTel == null || this.registerForm.mobileTel == ''){
                            this.tabIndex = 3
                        }
                    }

                }
            },

            register(){
                this.$swal.fire({
                    text: this.$t('do_you_want_to_complete_registration'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            RegistrationService.register(this.$route.params.id)
                            .then(response => {
                                let student_program_id = response.data.data.student_program.id
                                let formData = {
                                    language: this.$i18n.locale.toUpper()
                                }
                                StudentDocumentService.downloadRegistrationForm(student_program_id, formData).then(response => {
                                    this._downloadFile(response,this.$t('student_registration_form') +'.pdf')
                                })
                                this.$toast.success(this.$t('api.'+response.data.message));
                            }).catch(e => {
                                this.showErrors(e);
                            })
                        }
                    })
            },

            setProgramLanguage(programCode){
                ProgramService.get(programCode).then(response => {
                    this.programLanguage = response.data.data.language
                })
            },

            upload(index){
                if (this.checkPermission("registration_document")) {
                    setTimeout(() => {
                        if (this.documents[index]) {
                            let formData = new FormData()
                            formData.append('document_id', this.documents[index].id)
                            formData.append('file', this.files[index])
                            RegistrationService.uploadDocuments(this.$route.params.id, formData).then(response => {
                                this.getDocuments()
                                this.$toast.success(this.$t('api.' + response.data.message));
                            }).catch(e => {
                                if (e.status == 422) {
                                    this.$toast.error(e.data.errors.file[0]);
                                }
                                if (e.status == 406) {
                                    this.$toast.error(this.$t('api.' + e.data.message));
                                }
                            }).finally(() => {
                                this.files[index] = null;
                            });
                        }
                    }, 2000)
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },

            download(uuid){
                if (this.checkPermission("registration_documentdownload")) {
                    RegistrationService.downloadDocuments({uuid: uuid}).then(response => {
                        const blob = new Blob([response.data], {type: response.headers['content-type']})
                        const objectUrl = window.URL.createObjectURL(blob)
                        window.open(objectUrl)
                    }).catch(e => {
                        if (e.status == 422) {
                            this.$toast.error(e.data.errors.file[0]);
                        }
                        if (e.status == 406) {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    })
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },

            remove(uuid){
                if (this.checkPermission("registration_documentdelete")) {
                    RegistrationService.deleteDocuments(uuid).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.getDocuments()
                    }).catch(e => {
                        if (e.status == 422) {
                            this.$toast.error(e.data.errors.file[0]);
                        }
                        if (e.status == 406) {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    })
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
            },

            setMissingDocuments(){
                setTimeout(() => {
                    this.documents.forEach((item, index) => {
                        this.registerForm.missingDocuments.forEach((itm) => {
                            if(item.id == itm.document_type_id){
                                this.dates[index] = itm.document_due_date
                            }
                        })
                    })
                }, 2000)

            },

            getJobs(){
                ParameterService.getItems('jobs').then(response => {
                    this.parameterJobs = response.data.data.items
                })
            },

            parseJobFields(jobData){
                let result = {
                    job: null,
                    other: null
                }

                if(jobData != null){
                    this.parameterJobs.forEach(itm => {
                        if(itm.code == jobData){
                            result.job = itm.code
                        }
                    })

                    if(result.job == null){
                        result.job = 'Diğer'
                        result.other = jobData
                    }
                }

                return result
            },

            handleJobInput(data, which){
                if(data == 'Diğer'){
                    if(which == 'mother'){
                        this.registerForm.mothersJobOther = null
                    } else if(which == 'father') {
                        this.registerForm.fathersJobOther = null
                    }
                }
            },
        },

        watch: {
            'registerForm.programCode': {
                handler: function (val) {
                    this.setProgramLanguage(val)
                }
            },
        },

        created() {
            this.getJobs()
            this.getData()
            this.getDocuments()
            this.setMissingDocuments()

        }
    };
</script>

